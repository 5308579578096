import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  Cart, LoyaltyOfferOperation, PromotionNotAppliedWarning, useApplyLoyaltyOfferMutation, YumCart
} from '@pizza-hut-us-development/client-core';
import logger from '@/common/logger';
import { RewardsMessage } from '@/rewards/t3Iframe/T3Iframe.types';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { openLocalizationRail, openModal } from '@/localization/actions';
import { onAccountRewardsClickRedeem } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { RootState } from '@/rootStateTypes';
import { isRedemptionWarning } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import { openCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import { RedemptionQueryParams } from '@/clientCore/redemptionMenu/constants';

export interface RedeemLoyaltyRewards {
  redeem(coupon: RewardsMessage['coupon']['key'] | string, event?: MessageEvent): void;
  getInProgressLoyaltyCodes: (cart: Cart) => string[];
}

const useRedeemLoyaltyRewards = (): RedeemLoyaltyRewards => {
  const userIsLocalized = useSelector(localizationSelectors.isLocalized);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const analytics = useAnalytics();

  const [applyLoyaltyOffer] = useApplyLoyaltyOfferMutation();

  const getInProgressLoyaltyCodes = (cart: Cart) => {
    // TODO: Remove typecast when CC type is updated
    const appliedCodes: string[] = (cart as YumCart).appliedPromotions?.reduce((acc: string[], promotion) => {
      const loyaltyOfferId = promotion.loyaltyOfferIds?.[0];
      if (loyaltyOfferId && !acc.includes(loyaltyOfferId)) return [...acc, loyaltyOfferId];
      return acc;
    }, []) || [];

    // TODO: Remove typecast when CC type is updated
    const codesInWarnings: string[] = (cart as YumCart).warnings?.reduce((acc: string[], warning) => {
      if (!isRedemptionWarning(warning)) return acc;
      const loyaltyOfferId = warning.loyaltyOffer?.loyaltyOfferId;
      if (loyaltyOfferId && !acc.includes(loyaltyOfferId)) return [...acc, loyaltyOfferId];
      return acc;
    }, []) || [];

    return [...appliedCodes, ...codesInWarnings];
  };

  const redeem = async (redemptionCode: number | string, event?: MessageEvent) => {
    analytics.push(() => onAccountRewardsClickRedeem());

    if (isYumEcomm && typeof redemptionCode === 'string') {
      const addLoyaltyItemToCart = async () => {
        const loyaltyResponse = await applyLoyaltyOffer({
          loyaltyOfferId: redemptionCode,
          operation: LoyaltyOfferOperation.APPLY
        });
        // Failed call
        if ('error' in loyaltyResponse) {
          dispatch(openModal({
            title: 'We\'re sorry',
            body: 'Something went wrong. Please try again.',
            cta: {
              text: 'OK'
            }
          }));
          logger.withoutTelemetry.error(`Loyalty item ${redemptionCode} failed to add to cart`);
          return;
        }
        const associatedPromotionId = (loyaltyResponse.data.warnings?.find((warning) => {
          if (!isRedemptionWarning(warning)) return false;
          return warning.loyaltyOffer?.loyaltyOfferId === redemptionCode;
        }) as PromotionNotAppliedWarning)?.promotionId;

        // If no associated warning promotion id found - loyalty item was already in cart
        if (!associatedPromotionId) {
          dispatch(openCartRail());
        } else {
          router.push(`/redeem?red=${associatedPromotionId}&fl=${RedemptionQueryParams.EFFECTS}`);
        }

        if (!event) return;
        // Post message to iframe with any in progress loyalty items
        event.source?.postMessage({ cartItems: getInProgressLoyaltyCodes(loyaltyResponse.data) }, { targetOrigin: event.origin });
      };

      if (!userIsLocalized) {
        dispatch(openLocalizationRail({
          handleAfterLocalizing: () => {
            addLoyaltyItemToCart();
          }
        }));
      } else {
        addLoyaltyItemToCart();
      }
    }
  };

  return {
    redeem,
    getInProgressLoyaltyCodes
  };
};

export default useRedeemLoyaltyRewards;
